import React, {useCallback, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useActions } from "src/hooks/useActions";
import Select from "src/components/Select";
import { ReactComponent as CalendarIcon } from "src/assets/img/calendar.svg";
import Pagination from "../../components/Pagination";
import userDefaultImg from "src/assets/img/default-user.jpg";

import {
  CustomerInterface,
  CustomerInfoInterface
} from "src/types/customer";

import { Wrapper } from "./styled";
import {getPosUserIdListAxios, getStaffListAxios} from "../../api/staffPerformance";
import {SelectorListObj} from "../../components/Select/types";
import moment from "moment/moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

const StaffPerformance = () => {
  const {loggedUser} = useTypedSelector((state) => state.auth);
  const setStartDate = () =>{
    const day = loggedUser.user.created.split(' ')[0].split('-')[1]
    const month = loggedUser.user.created.split(' ')[0].split('-')[0]
    const year = loggedUser.user.created.split(' ')[0].split('-')[2]
    return `${year}-${month}-${day}`
  }
  const [dates, setDatesState] = useState({
    startDate:moment(setStartDate()).format("MMM D, YYYY"),
    endDate: moment(new Date()).format("MMM D, YYYY")
  });
  const [requestParameters, setRequestParameters] =
    useState<{limit: string | number,
      page: string | number,
      locationId: string | number,
      posUserId: string,
      }>({
      limit: 10,
      page: 1,
      locationId: "",
      posUserId: "",
    });
  const [staffListLoading, setStaffListLoading] =
    useState<boolean>(false);
  const [staffList, setStaffList] =
    useState<Array<CustomerInterface> | null>(null);
  const [posUsersListToSelect, setPosUsersListToSelect] = useState<SelectorListObj[]>([])

  const [staffListInfo, setStaffListInfo] =
    useState<CustomerInfoInterface>({
      count_users: 0,
      limit: 0,
      page: 0,
      total_pages_count: 0,
      total_users_count: 0,
    });

  const { locations } = useTypedSelector(
    (state) => state.groupsLocations
  );

  const { getGroupsLocations } = useActions();
  
  useEffect(() => {
    getGroupsLocations();
  }, []);
  
  const getPosUserIdList = useCallback(() => {
    getPosUserIdListAxios({locationId: requestParameters.locationId}).then(res =>{
      const selectObj: SelectorListObj[] = []
      res.data.posUsers.forEach((posUser: { posUserId: string }) => {
        selectObj.push({value: posUser.posUserId, label: posUser.posUserId})
      })
      setPosUsersListToSelect(selectObj);
      handleChangeRequestParamtrs('posUserId', '')
    })
  }, [requestParameters.locationId]);
  
  useEffect(() => {
    getPosUserIdList();
  }, [getPosUserIdList]);
  
  const getAllStaffs = useCallback(() => {
    setStaffListLoading(true)
    getStaffListAxios({...requestParameters, startDate: moment(dates.startDate).format('YYYY-MM-DD'), endDate: moment(dates.endDate).format('YYYY-MM-DD')}).then(res =>{
      setStaffList(res.data.posUsers)
      setStaffListInfo({
        count_users: res.data.countStaff,
          limit: +res.data.limit,
        page: +res.data.page,
        total_pages_count: res.data.totalPagesCount,
        total_users_count: res.data.totalStaffCount,
      
      })
    }).finally(() => setStaffListLoading(false))
  }, [requestParameters, dates])
  
  useEffect(() => {
    getAllStaffs()
  }, [getAllStaffs]);

  const handleChangeRequestParamtrs = (
    name: string,
    value: string | number | Date
  ) => {
    setRequestParameters((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const staffPerformanceTableColumns = [
    {
      name: "",
      width: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div className="photo">
            {row.image ? (
              <img src={row.image} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      }
    },
    {
      name: "Staff Member/POS I.D",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div className={'full-name-wrapp'} >
            <p>{row.posUserId}</p>
          </div>
        );
      }
    },
    {
      name: "Store",
      cell: (row: any): JSX.Element => {
        return <div>{row.locationName || "-"}</div>;
      }
    },
    {
      name: "New Customers",
      cell: (row: any): JSX.Element => {
        return <div>{row.newCustomers || "0"}</div>;
      }
    },
    {
      name: "Points Issued",
      cell: (row: any): JSX.Element => {
        return <div>{row.points ?? "0"}</div>;
      }
    },
    {
      name: "Stamps Issued",
      minWidth: "120px",
      cell: (row: any): JSX.Element => {
        return <div>{row.stamps ?? "0"}</div>;
      }
    }
  ];
  
  const setDates = (e: any, { startDate, endDate }: any) => {
    endDate >= moment() && startDate >= moment() ? setDatesState({
      startDate: moment().format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : endDate >= moment() &&  startDate <= moment() ? setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: endDate.format("MMM D, YYYY")
    });
  };
  
  const ranges = {
    "Today": [moment(), moment()],
    "Last 7 Days": [moment().subtract(1, "weeks"), moment()],
    "Last 30 Days": [moment().subtract(1, "months"), moment()],
    "Last Year": [moment().subtract(1, "years"), moment()],
  };
  
  return (
    <Wrapper>
      <div className="page-title">Staff Performance</div>
      <div className="top-bar">
        <div className="groups-wrap">
          <Select
            placeholder="All Staff"
            selectorList={posUsersListToSelect}
            defaultValue={requestParameters.posUserId.length > 0 ? posUsersListToSelect.find(pos => pos.value === requestParameters.posUserId) : {
              value: '',
              label: ''
            }}
            disabled={!(posUsersListToSelect.length > 0)}
            positionForShow="bottom"
            required
            isClearable
            getValue={(value) => handleChangeRequestParamtrs("posUserId", value)}
            hideSelectorFilter
          />
        </div>
        <div className="locations-wrap">
          <Select
            placeholder="All Locations"
            selectorList={locations}
            disabled={!(locations.length > 0)}
            positionForShow="bottom"
            required
            isClearable
            getValue={(value) => handleChangeRequestParamtrs("locationId", value)}
            hideSelectorFilter
          />
        </div>
        <div className="groups-wrap" style={{minWidth: '200px', width: "auto"}}>
          <DateRangePicker
            onApply={setDates}
            initialSettings={{startDate: moment(setStartDate()), endDate: new Date(), ranges: ranges}}
          
          >
            <div className={'isSelect select'}>
              <CalendarIcon/>
              <input
                type="text"
                value={dates.startDate + "-" + dates.endDate}
                onChange={(e) => {
                  // @ts-ignore
                  setDates(e, dates)
                }}
                className="form-control"
              />
            </div>
          
          </DateRangePicker>
        </div>
      </div>
      <div
        className={`table-wrap ${staffListLoading ? "loading-blue" : ""}`}
      >
        <div className="loading-innerwrap">
          {staffList !== null ? (
            staffList.length > 0 ? (
              <>
                <div className="table">
                  <DataTable
                    noHeader
                    columns={staffPerformanceTableColumns}
                    data={staffList}
                  />
                </div>
                <Pagination {...staffListInfo} requestLimit={`${requestParameters.limit}`}
                            setRequestParamtrs={setRequestParameters}/>
              </>
            ) : (
              <div className="nothing-found">Sorry, nothing found</div>
            )
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
};

export default StaffPerformance;
