import React, {useCallback, useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import Select from "../../../components/Select";
import {SelectorListObj} from "../../../components/Select/types";
import {
  getAdminBusinessesNamesAxios,
  getAdminOneBusinessAxios,
  updateAdminOneBusinessAxios
} from "../../../api/adminPanel";
import CheckBox from "../../../components/CheckBox/CheckBox";
import {
  getOneBusinessOptionsAxios,
  updateBusinessOptionsAxios
} from "../../../api/businessPlan";

const AdminBusinessesSetings = () => {
  const [businesses, setBusinesses] = useState<SelectorListObj[]>([{value: '', label: ''}])
  const [selectedBusiness, setSelectedBusiness] = useState<any>({})
  const [businessSettingsOptions, setBusinessSettingsOptions] = useState<string[]>([])
  const [updatedData, setUpdatedData] = useState<boolean>(true)
  const [westmorlandStaff, setWestmorlandStaff] = useState<boolean>(false)
  
  const getOneBusinessOptions = useCallback((id)=>{
    getOneBusinessOptionsAxios({id}).then(res=>{
      setWestmorlandStaff(res.data.staff_member_type.value === 'westmorland')
    })
  },[])
  
  const updateBusinessOptions =()=>{
    const data = {
      name: "staff_member_type",
      value: westmorlandStaff ? "westmorland" : 'standard'
    }
    
    updateBusinessOptionsAxios({id: selectedBusiness.id, data}).then(res=>{
    })
  }
  
  useEffect(() => {
    getAdminBusinessesNamesAxios().then(({data}) => {
      let selectBusiness: any[] = []
      data.business.forEach((item: any) => {
        selectBusiness.push({value: item.id, label: item.business_name})
      })
      getOneBusiness(data.business[0].id)
      setBusinesses(selectBusiness)
    });
  }, []);
  
  const getOneBusiness = (id: number) =>{
    getAdminOneBusinessAxios({id}).then(({data}) =>{
      setUpdatedData(true)
      getOneBusinessOptions(id)
      setBusinessSettingsOptions(Object.keys(data.business).filter(key=>key.includes('allow')))
      setSelectedBusiness(data.business)
    })
  }
  
  const changeSettingsField = (name: string, value: boolean) =>{
    setUpdatedData(false)
    name === 'allow_staff' && !value ? setSelectedBusiness({...selectedBusiness, [name]: value, 'allow_staff_shared': false, 'allow_campaigns': false}) :
    setSelectedBusiness({...selectedBusiness, [name]: value})
  }
  
  const createRequestObj = () =>{
    const data: any = {id: selectedBusiness.id}
    businessSettingsOptions.forEach((key: string) => {
      return data[key] = selectedBusiness[key]
      })
    return data
  }
  
  const updateBusiness = () => {
    updateBusinessOptions()
    updateAdminOneBusinessAxios(createRequestObj()).then(res=>{
      setUpdatedData(true)
     setSelectedBusiness(res.data.business)
    })
  }
  
  const showOptionLabel = (option: string) => {
    return option === 'allow_pos' ? 'Pos Rules' : option === 'allow_cbePP' ? 'CBE Pixel Point' : option === 'allow_min_spend' ? 'Minimum Spend' : option.replace('allow_', '').replaceAll('_', ' ')
  }
  
  return (
    <Wrapper>
      <div className="header">
        <h2>Businesses settings</h2>
      </div>
      <div className="groups-wrap">
        <p className="label">Select a business for settings</p>
        <Select
          placeholder="All Businesses"
          selectorList={businesses}
          disabled={!(businesses.length > 0)}
          defaultValue={businesses[0]}
          positionForShow="bottom"
          required
          isClearable
          getValue={(value) => getOneBusiness(+value)}
          hideSelectorFilter
        />
      </div>
      <div className={'settings_content'}>
        {selectedBusiness.business_name && (
          <>
            <h3 className="settings_name">Settings business {selectedBusiness.business_name}</h3>
            <div className="settings_fields">
              {businessSettingsOptions.map((option: string, index: number) => (
                (!selectedBusiness.allow_staff && option === 'allow_staff_shared') || (!selectedBusiness.allow_staff && option === 'allow_campaigns') ? null :
                  <div key={`${option}-${index}`} className="field">
                    <CheckBox
                      label={`Allow ${showOptionLabel(option)}`}
                      isChecked={
                        !!selectedBusiness[option]
                      }
                      onChange={(isChecked) => {
                        changeSettingsField(option, isChecked)
                      }}
                    />
                  </div>))}
              <div className="field">
                <CheckBox
                  label={`Westmorland Staff Members`}
                  isChecked={
                    westmorlandStaff
                  }
                  onChange={(isChecked) => {
                    setWestmorlandStaff(isChecked);
                    setUpdatedData(false)
                  }}
                />
              </div>
              </div>
              <div className="button-row">
                <button type="submit" disabled={updatedData} onClick={updateBusiness} className="btn-blue">
                  Update Details
                </button>
              </div>
            </>
            )}
          </div>
          </Wrapper>
          );
        };
        
        export default AdminBusinessesSetings;
