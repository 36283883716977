import React, { FC, useEffect, useState } from "react";
import { format } from "date-fns";
import DataTable from "react-data-table-component";

import userDefaultImg from "src/assets/img/default-user.jpg";
import { CustomerUserInterface } from "src/types/customer";
import EditablePoint from "./EditablePoinr";
import { ReactComponent as VisibilityIcon } from "src/assets/img/visibility.svg";
import { getCustomerInfoAxios } from "../../../../api/customers";

import { WrapperTableList } from "./styled";
import TransactionInformation from "../TransactionInformation/TransactionInformation";
import moment from "moment";

interface IProps {
  data: any;
  getCustomerList: any;
  requestParamtrs: any;
}

function vouchersName(vouchers: any[]) {
  return vouchers.reduce((acc: any, voucher: any) => {
    const { reward_name } = voucher;
    acc.push(reward_name);
    return acc;
  }, []).join(", ") || "-";
}

function getRewards(info: CustomerUserInterface, key: string) {
  const { vouchers, stamp_activity, used_vouchers } = info;

  if (key === "earned") {
    // @ts-ignore
    return vouchers.length ? vouchersName(vouchers) : stamp_activity ? stamp_activity[key] : "-";
  } else {
    // @ts-ignore
    return used_vouchers.length ? vouchersName(used_vouchers) : stamp_activity ? stamp_activity[key] : "-";
  }

}

const ExpandableTableList: FC<IProps> = ({ data, getCustomerList, requestParamtrs }) => {
  const [userInfo, setUserInfo] = useState<Array<CustomerUserInterface> | null>(null);
  const { photo, first_name, last_name, promotion_type } = data;
  const type = promotion_type === "Stamp" ? "Stamps" : "Points";
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [customerInformation, setCustomerInformation] = useState<any>({});
  const [customer, setCustomer] = useState<any>({});

  const getUserPointInfo = (id: string) => {
    console.log(id, requestParamtrs.search, requestParamtrs.searchType, requestParamtrs.location, moment(requestParamtrs.startDate).format("DD-MM-yyyy"), moment(requestParamtrs.endDate).format("DD-MM-yyyy"))
    getCustomerInfoAxios(id, requestParamtrs.search, requestParamtrs.searchType, requestParamtrs.location, moment(requestParamtrs.startDate).format("DD-MM-yyyy"), moment(requestParamtrs.endDate).format("DD-MM-yyyy")).then((response) => {
      setUserInfo(response.data.activities);
    });
  };

  useEffect(() => {
    getUserPointInfo(data.id);
  }, [data.id, requestParamtrs]);

  const customerTableColumns = [
    {
      name: "",
      width: "50px",
      cell: (): JSX.Element => {
        return (
          <div className="photo">
            {data.photo ? (
              <img src={photo} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      }
    },
    {
      name: "",
      minWidth: "150px",
      cell: (): JSX.Element => {
        return (
          <div>
            {first_name} {last_name}
          </div>
        );
      }
    },
    {
      name: type,
      center: true,
      cell: ({ min_points, can_edit, points, activity_id, stamp_activity }: CustomerUserInterface): JSX.Element => {
        return <EditablePoint minValue={min_points} canEdit={can_edit} points={points}
                              pointId={activity_id} getCustomerList={getCustomerList} stamp_activity={stamp_activity}
                              getUserPointInfo={getUserPointInfo} userId={data.id} />;
      }
    },
    {
      name: `${(type === "Points") ? "Reward" : type} Earned`,
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{getRewards(row, "earned")}</div>;
      }
    },
    {
      name: `${(type === "Points") ? "Reward" : type} Used`,
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{getRewards(row, "used")}</div>;
      }
    },
    {
      name: "Staff i.d.",
      omit: type !== "Stamps",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.seller || "-"}</div>;
      }
    },
    {
      name: "Store",
      omit: type === "Stamps",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.store || "-"}</div>;
      }
    },
    {
      name: "POS.I.D",
      omit: type === "Stamps",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.pos_id || "-"}</div>;
      }
    },
    {
      name: "POS User .I.D",
      omit: type === "Stamps",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.pos_user_id || "-"}</div>;
      }
    },
    {
      name: "Receipt No.",
      omit: type === "Stamps",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.receipt || "-"}</div>;
      }
    },
    {
      name: "Time",
      minWidth: "120px",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return (
          <div>
            {row.date
              ? format(new Date(row.date), "dd/MM/yyyy, HH:mm:ss")
              : "-"}
          </div>
        );
      }
    },
    {
      name: "Basket",
      maxWidth: "75px",
      right: true,
      cell: (row: any, i: number): JSX.Element => {
        return (
          <>
            {row.basket ? (<div className={"show-order"} data-tag="allowRowEvents">
              <VisibilityIcon data-tag="allowRowEvents" className={"visibility-icon"} />
            </div>) : null}

          </>
        );
      }
    }
  ];

  return (
    <WrapperTableList>
      {!!userInfo &&
        <>
          <DataTable
            data={userInfo}
            columns={customerTableColumns}
            noHeader
            onRowClicked={(row: any) => {
              if(row.basket && row.basket.productsList.length > 0){
                setShowTransactionDetail(true);
                row.basket && setCustomerInformation(row.basket);
                setCustomer(row);
              } else {
                setShowTransactionDetail(false);
              }
            }}
            expandableRows
            expandableRowsHideExpander={true}
            expandableRowExpanded={(row) => (row === customer)}
            onRowExpandToggled={(bool, row) => setCustomer(row)}
            expandOnRowClicked={true}
            expandableRowsComponent={
              <TransactionInformation show={showTransactionDetail} customerInformation={customerInformation}
                                      productList={customerInformation.productsList} customer={customer}
                                      setShow={setShowTransactionDetail} />
            }
          />

        </>
      }
    </WrapperTableList>

  );
};

export default ExpandableTableList;
