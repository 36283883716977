import React, { FC, useState, useEffect } from "react";

import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useActions } from "src/hooks/useActions";
import Select from "src/components/Select";
import { ReactComponent as PlacePointIcon } from "src/assets/img/place-point.svg";
import { ReactComponent as CalendarIcon } from "src/assets/img/calendar.svg";
import {ReactComponent as ArrowDown} from "../../assets/img/arrowDown.svg";
import { DashboardInfoInterface } from "src/types/dashboard";
import { getDashboardInfoAxios } from "src/api/dashboard";
import { Wrapper } from "./styled";
import { filterPerDate } from "./consts";
import DashboardChart from "./DashboardChart";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

const Dashboard: FC = () => {
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [locationIdFilter, setLocationIdFilter] = useState<number>(0);
  const [dateFilter, setDateFilter] = useState<{
    value: string;
    label: string;
  }>({
    value: filterPerDate[0].value,
    label: filterPerDate[0].label
  });
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const [openChannels, setOpenChannels] = useState<boolean>(false)
  const [dashboardInfo, setDashboardInfo] = useState<DashboardInfoInterface>({
    allCustomers: 0,
    countAllCheckIns: 0,
    countOfActivatedCards: 0,
    countOfActivatedVouchers: 0,
    countOfRedeemCampaign: 0,
    averagePriceSales: 0,
    avTimeBetweenRepeatVisits: 0,
    avTimeBetweenVoucherUsed: 0,
    vouchers_used_sum_reward: 0,
    vouchers_earned_sum_reward: 0,
    topProduct: [],
    loyalty_visits: [],
    total_loyalty_volume: [],
    av_loyalty_visits: [],
    av_voucher_used_value: [],
    point: 0,
    customers: [0],
    locations: [],
    newCustomers: 0,
    promotionType: "Stamp",
    salesStatistic: [0]
  });

  const { locations } = useTypedSelector((state) => state.groupsLocations);
  const { getGroupsLocations } = useActions();
  const [dates, setDatesState] = useState({
    startDate:moment(new Date()).subtract(7, "days").format("MMM D, YYYY"),
    endDate: moment(new Date()).format("MMM D, YYYY")
  });

  useEffect(() => {
    if (locations.length === 0) getGroupsLocations();
    }, []); //eslint-disable-line

  useEffect(() => {
    setPageLoading(true);

    getDashboardInfoAxios({
      date: +dateFilter.value,
      startDate: moment(dates.startDate).format("yyyy-MM-DD"),
      endDate: moment(dates.endDate).format("yyyy-MM-DD"),
      locationId: locationIdFilter
    }).then((res) => {
      setDashboardInfo(res.data.data);
      setPageLoading(false);
      setDateFilter({value: res.data.daysDiff, label: res.data.daysDiff})
    });
  }, [dateFilter.value, locationIdFilter, dates.startDate, dates.endDate]);
  
  
  
  const setDates = (e: any, { startDate, endDate }: any) => {
    endDate >= moment() && startDate >= moment() ? setDatesState({
      startDate: moment().format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : endDate >= moment() &&  startDate <= moment() ? setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: endDate.format("MMM D, YYYY")
    });
  };
  
  const ranges = {
    "Today": [moment(), moment()],
    "Last 7 Days": [moment().subtract(1, "weeks"), moment()],
    "Last 30 Days": [moment().subtract(1, "months"), moment()],
    "Last Year": [moment().subtract(1, "years"), moment()],
  };
  
  return (
    <Wrapper className={`${pageLoading ? "loading-blue" : ""}`}>
      <div className="heading">
        <div className="page-title">Dashboard</div>
        <div className="filters">
          <Select
            placeholder="All locations"
            selectorList={locations}
            disabled={!(locations.length > 0)}
            positionForShow="bottom"
            isClearable
            icon={<PlacePointIcon/>}
            required
            hideSelectorFilter
            getValue={(value) => {
              setLocationIdFilter(+value);
            }}
          />
          <DateRangePicker
            onApply={setDates}
            initialSettings={{ startDate: moment(new Date()).subtract(7, "days"), endDate: new Date(), ranges: ranges }}
            
          >
            <div className={'isSelect select'}>
              <CalendarIcon/>
              <input
                type="text"
                value={dates.startDate + "-" + dates.endDate}
                onChange={(e) => {
                  // @ts-ignore
                  setDates(e, dates)
                }}
                className="form-control"
              />
            </div>
            
          </DateRangePicker>
        </div>
      </div>
      <div className="main-blocks-row">
        <div className="item all-customers">
          <div className="title">All Customers</div>
          <div className="count">{dashboardInfo.allCustomers}</div>
          <div className="description">overall</div>
        </div>
        <div className="item all-customers">
          <div className="title">New Customers</div>
          <div className="count">{dashboardInfo.newCustomers}</div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        <div className="item all-customers">
          <div className="title">Check-Ins</div>
          <div className="count">{dashboardInfo.countAllCheckIns}</div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        <div className="item all-customers">
        <div className="title">
            {dashboardInfo.promotionType === "Stamp"
              ? "Count of activated cards"
              : "Total points"}
          </div>
          <div className="count">
            {dashboardInfo.promotionType === "Stamp"
              ? dashboardInfo.countOfActivatedCards
              : dashboardInfo.point}
          </div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        <div className="item all-customers">
          <div
            className="title">{dashboardInfo.promotionType === "Stamp" ? "Redeemed Campaigns" : "Count of activated vouchers"}</div>
          <div className="title"></div>
          <div
            className="count">{dashboardInfo.promotionType === "Stamp" ? dashboardInfo.countOfRedeemCampaign : dashboardInfo.countOfActivatedVouchers}</div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        
      </div>
      {loggedUser.user.allow_pos ? (
        <>
          <div className="main-blocks-row" style={{marginTop: '30px'}}>
            <div className="item all-customers">
              <div className="title">Average Order Value</div>
              <div className="count">{loggedUser.user.currency_symbol || '€'}{dashboardInfo.averagePriceSales}</div>
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
            <div className="item all-customers">
              <div className="title">Top Selling Products</div>
              {dashboardInfo.topProduct.map((product: any, index: number) => (
                index <=2 && <div key={`${product.product_id}_${product.name}`} className={'sales_item products'}><p
                  className={'sale_name'}>{product.name}</p> <p>{product.quantity}</p></div>))}
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
            <div className="item all-customers">
              <div className="title">Av Time Between Repeat Visits</div>
              <div className="count">{dashboardInfo.avTimeBetweenRepeatVisits} <span className={'detail'}>Days</span></div>
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
            <div className="item all-customers">
              <div className="title">Vouchers Information</div>
              <div className={'sales_item products'}><p
                className={'sale_name'} style={{paddingRight: '10px'}}>Vouchers Used:</p> <p>{loggedUser.user.currency_symbol || '€'}{dashboardInfo.vouchers_used_sum_reward ? dashboardInfo.vouchers_used_sum_reward : '0'}</p></div>
              <div className={'sales_item products'}><p
                className={'sale_name'} style={{paddingRight: '10px'}}>Vouchers In Circulation:</p> <p>{loggedUser.user.currency_symbol || '€'}{dashboardInfo.vouchers_earned_sum_reward ? dashboardInfo.vouchers_earned_sum_reward : '0'}</p></div>
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
            <div className="item all-customers">
              <div className="title">Av Time between Vouchers earned vs Used</div>
              <div className="count">{+dashboardInfo.avTimeBetweenVoucherUsed} <span className={'detail'}>Days</span></div>
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
          </div>
          {dashboardInfo.promotionType === 'Points' ?
          <button className="btn-white sales_channels" onClick={() => {
            setOpenChannels(!openChannels)
          }}>
            <span>Sales Channels: </span>
            <span className={openChannels ? "icon icon_rotate" : "icon"}>
              <ArrowDown/>
            </span>
          </button> : null
          }
          {openChannels ? (<div className="main-blocks-row" style={{marginTop: '30px'}}>
            <div className="item all-customers">
              <div className="title">Total Loyalty Volume</div>
              {dashboardInfo.total_loyalty_volume && dashboardInfo.total_loyalty_volume.length> 0 && dashboardInfo.total_loyalty_volume.map(sale => (
                <div key={`loyalty_volume_${sale.sum_sale}_${sale.location_name}`} className={'sales_item'}><p
                  className={'sale_name'}>{sale.location_name}</p> <p>{loggedUser.user.currency_symbol || '€'}{sale.sum_sale}</p></div>))}
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
            <div className="item all-customers">
              <div className="title">Loyalty Visits</div>
              {dashboardInfo.loyalty_visits && dashboardInfo.loyalty_visits.length > 0 && dashboardInfo.loyalty_visits.map(sale => (
                <div key={`loyalty_visits_${sale.visits}_${sale.location_name}`} className={'sales_item'}><p
                  className={'sale_name'}>{sale.location_name}</p> <p>{sale.visits}</p></div>))}
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
            <div className="item all-customers">
              <div className="title">Av Number of Visits</div>
              {dashboardInfo.av_loyalty_visits.map(sale => (
                <div key={`visits_${sale.av_visits}_${sale.location_name}`} className={'sales_item'}><p
                  className={'sale_name'}>{sale.location_name}</p> <p>{sale.av_visits}</p></div>))}
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
            <div className="item all-customers">
              <div className="title">Av Value of Vouchers Used</div>
              {dashboardInfo.av_voucher_used_value.map(sale => (
                <div key={`voucher_used${sale.av_value_voucher_used}_${sale.location_name}`} className={'sales_item'}><p
                  className={'sale_name'}>{sale.location_name}</p> <p>{loggedUser.user.currency_symbol || '€'}{sale.av_value_voucher_used}</p></div>))}
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
          </div>) : null}
        </>
      
      ) : null}
      <DashboardChart
        promotionType={dashboardInfo.promotionType}
        customers={dashboardInfo.customers}
        salesStatistic={dashboardInfo.salesStatistic}
        dateFilter={+dateFilter.value}
        statisticByLocation={dashboardInfo.locations}
        endDate={dates.endDate}
      />
    </Wrapper>
  );
};

export default Dashboard;
