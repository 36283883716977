import React, {useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import Select from "../Select";
import {ReactComponent as UploadIcon} from "src/assets/img/upload.svg";
import {ReactComponent as CheckCircle} from "../../assets/img/checkCircle.svg";
import {ReactComponent as ArrowDown} from "../../assets/img/arrowDown.svg";
import {ReactComponent as Edit} from "../../assets/img/edit.svg";
import {ReactComponent as Delete} from "../../assets/img/delete.svg";
import {ReactComponent as CirclePlus} from "src/assets/img//circle-plus.svg";
import {DropEvent, FileRejection, useDropzone} from "react-dropzone";
import Textarea from "../Textarea";
import Input from "../Input";
import Calendar from "../Calendar";
import {AdvertsOffersType, AdvertsPartnerRequestType, AdvertsPartnerType} from "../../types/adminTypes";
import {
  createAdvertOutletsAxios,
  createAdvertPartnerAxios,
  getAdminBusinessesNamesAxios, moveAdvertPartnersAxios,
  removeAdvertOutletsAxios,
  removeAdvertPartnerAxios,
  uploadPhotoAxios
} from "../../api/adminPanel";
import {SelectorListObj} from "../Select/types";
import Checkbox from "../CheckBox";

interface IAdminPartnersItem {
  advertList: AdvertsPartnerType[],
  type: string,
  setPartners: (list: any[]) => void,
  offers: boolean,
  getAllPartners: () => void
}

const AdminPartnersItem = ({advertList, setPartners, type, offers = false, getAllPartners}: IAdminPartnersItem) => {
  const [partnersList, setPartnersList] = useState<AdvertsPartnerType[]>([])
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);
  const [focusInput, setFocusInput] = useState<number>(0);
  
  const onDrop = (acceptedFiles: any, fileRejections: FileRejection[], event: DropEvent) => {
    if (acceptedFiles[0]) {
      setLoadingPhoto(true);
      const requestFormData: any = new FormData();
      requestFormData.append("file", acceptedFiles[0]);
      uploadPhotoAxios(requestFormData)
        .then((res: any) => {
          // @ts-ignore
          changePartnerParameters(focusInput, false, 'logo', res.data.photo_url)
          // setLogo(+event.target.className, res.data.photo)
          setLoadingPhoto(false);
        })
        .catch(() => {
          setLoadingPhoto(false);
        });
    }
  };
  useEffect(() => {
    setPartnersList(advertList);
  }, [advertList]);
  
  const changePartnerParameters = (id: number, date: boolean, columnName: string, value: Date | string | boolean | number| {
    value: string,
    label: string
  }) => {
    advertList[id] = {...advertList[id], [columnName]: value}
    setPartners([...advertList])
  }
  const {isDragActive, getRootProps, getInputProps} = useDropzone({
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    multiple: false,
    onDrop,
  });
  
  const [businesses, setBusinesses] = useState<SelectorListObj[]>([{value: '', label: ''}])
  useEffect(() => {
    getAdminBusinessesNamesAxios().then(({data}) => {
      let selectBusiness: any[] = []
      data.business.forEach((item: any) => {
        selectBusiness.push({value: item.id, label: item.business_name})
      })
      setBusinesses(selectBusiness)
    });
  }, []);
  
  const saveEditItem = (id: string | number) => {
    partnersList.forEach(item => item.id === id ? item.edit = false : null)
    handlerSetItem(partnersList.find(item => item.id === id))
  }
  const handlerSetItem = (data: any) => {
    if (type === 'partner') {
      const requestData : AdvertsPartnerRequestType = data.id ? {
        business_id: data.name,
        logo_url: data.logo,
        id: data.id
      } : {
        business_id: data.name,
        logo_url: data.logo
      }
      createAdvertPartnerAxios(requestData).then(res => {
        getAllPartners()
      })
    } else {
      const requestData : AdvertsOffersType = data.id ? {
        business_id: data.name,
        logo_url: data.logo,
        id: data.id,
        details: data.details,
        startDate: data.startDate/1000,
        endDate: data.endDate/1000,
        week_offer: data.week_offer,
        url: data.url,
        offerType: (type === 'outlets') ? 0 : 1
      } : {
        business_id: data.name,
        logo_url: data.logo,
        details: data.details,
        startDate: data.startDate/1000,
        endDate: data.endDate/1000,
        url: data.url,
        week_offer: data.week_offer,
        offerType: (type === 'outlets') ? 0 : 1
      }
      createAdvertOutletsAxios(requestData).then(res => {
        getAllPartners()
      })
    }
  }
  
  const createItem = (id: string | number) => {
    partnersList.forEach((partner: any) => {
      if (partner.id === id) {
        partner.edit = false
        partner.id = ''
        return partner
      }
    })
    handlerSetItem(partnersList.find(item => item.id === id))
  }
  const removeItem = (id: string | number) => {
    setPartners([...partnersList.filter((item) => item.id !== +id)])
    if (type === 'partner') {
      removeAdvertPartnerAxios(+id).then(res => {
        getAllPartners()
      })
    } else {
      removeAdvertOutletsAxios(+id).then(res => {
        getAllPartners()
      })
    }
  }
  const editPartnerData = (id: string | number) => {
    partnersList.forEach((partner: any) => {
      if (partner.id === id) {
        partner.edit = true
        return partner
      }
    })
    setPartners([...partnersList])
  }
  
  const addNewPartner = () => {
    setPartners([...advertList, {
      id: '',
      edit: true,
      name: businesses[0].value,
      details: '',
      startDate: Date.now(),
      endDate: Date.now(),
      url: '',
      logo: '',
      week_offer: false,
    }])
  }
  
  const movePartnerItem = (id: number, position: number) => {
    moveAdvertPartnersAxios({id, position}).then(res=>{
      getAllPartners()
    })
  }
  
  return (
    <Wrapper>
      <div className="header">
        <p className={'column__title'}>Business Name</p>
        <p className={'column__title'} style={offers ? {width: '10%'}: {width: '40%'}}>Logo {offers ? '(120 x 150)' : '(1920 x 1080)'}</p>
        {offers ? <>
          <p className={'column__title'}>Details</p>
          <p className={'column__title'}>Promotion Timeline</p>
          <p className={'column__title'}>URL</p>
          <p className={'column__title'} >Visit Count</p>
          <p className={'column__title'} style={{width: '5%'}}>Pinned</p>
        </> : <>
          <p className={'column__title'}>Position</p>
        </>}
        <p style={{width: '8%'}}></p>
      </div>
      <div className="main__item">
        {partnersList.length > 0 ?
          partnersList.map((item: any, index: number) => <div key={index} className={'item__wrapper'}>
            <div className="column">
              <Select selectorList={businesses} hideSelectorFilter
                      positionForShow={"bottom"}
                      disabled={!item.edit}
                      defaultValue={businesses.filter(stamp => stamp.value === item.name)[0] || businesses[0]}
                      getValue={(value) => item.name = value}
              />
            
            </div>
            <div className={`column ${!offers ? 'big_box' : ''}`} style={offers ? {width: '10%'}: {width: '40%'}}>
              <div
                className={`photo-wrap selectImg ${loadingPhoto ? "loading-blue" : ""}`}>
                <div
                  className={`dropZone ${
                    item.logo ? "with-photo" : ""
                  }`}
                >
                  <div {...getRootProps()} onMouseDown={()=> setFocusInput(index)} className={`active_${isDragActive}`}>
                    <input {...getInputProps()} className={`${index}`} required/>
                    {item.logo ? (
                      <div className="img">
                        <img src={item.logo} alt=""/>
                      </div>
                    ) : null}
                    <div className="dropZone-content-text">
                      <UploadIcon/>
                      <span className="text">
                      <span className="title">
                        {item.logo
                          ? "Update your logo"
                          : "Please upload your logo"}
                      </span>
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {offers ? <>
              <div className="column">
                <Textarea defaultValue={item.details}
                          disabled={!item.edit}
                          getValue={(value) => changePartnerParameters(index, false, 'details', value)}/>
              </div>
              <div className="column">
                <div className="groups-wrap">
                  <Calendar
                    selected={item.startDate}
                    onChange={(e) => e >= new Date() ? changePartnerParameters(index, true, 'startDate', e) : changePartnerParameters(index, true, 'startDate', Date.now())}
                    dateFormat="MMMM d, yyyy"
                    timeSelect={false}
                    label={"Start Date"}
                  />
                </div>
                <div className="groups-wrap">
                  <Calendar
                    selected={item.endDate}
                    onChange={(e) => e < item.startDate ? changePartnerParameters(index, true, 'endDate', item.startDate) : e <= new Date() ? changePartnerParameters(index, true, 'endDate', Date.now()) : changePartnerParameters(index, true, 'endDate', e)}
                    dateFormat="MMMM d, yyyy"
                    timeSelect={false}
                    label={"End Date"}
                  />
                </div>
              </div>
              <div className="column">
                <Input defaultValue={item.url}
                       disabled={!item.edit}
                       getValue={(value) => changePartnerParameters(index, false, 'url', value)}/>
              </div>
              <div className="column" style={{fontSize: '20px'}}>
                {item.tap_count}
              </div>
              <div className="column" style={{width: '5%'}}>
                <Checkbox
                  isChecked={
                    item.week_offer
                  }
                  isDisabled={!item.edit}
                  onChange={(isChecked) => {
                    changePartnerParameters(index, false, 'week_offer', isChecked)
                    
                  }}
                />
               </div>
            </> : <>
              <div className="column move-column">
                <button disabled={index === 0} className="btn-white move-btn" onClick={() => movePartnerItem(item.id, index)}>
                    <span className="icon icon-revert">
                      <ArrowDown/>
                    </span>
                  <span>Move Up</span>
                </button>
                <button disabled={index === partnersList.length -1} className="btn-white move-btn" onClick={() => movePartnerItem(item.id, index+2)}>
                    <span className="icon">
                      <ArrowDown/>
                    </span>
                  <span>Move Down</span>
                </button>
              </div>
            </>}
            <div className="buttons" style={{width: '8%'}} data-tag="allowRowEvents">
              {item.edit && item.id ?
                <button className="btn-white edit-category save-btn" onClick={() => saveEditItem(item.id)}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                  <span>Save</span>
                </button>
                : item.edit ?
                  <button className="btn-white edit-category save-btn" onClick={() => createItem(item.id)}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                    <span>Save</span>
                  </button> : <>
                    <button disabled={item.edit} className="btn-white edit-category"
                            onClick={() => editPartnerData(item.id)}>
                      <span className="icon">
                        <Edit/>
                      </span>
                    </button>
                    <button disabled={item.edit} className="btn-white remove-category"
                            onClick={() => removeItem(item.id)}>
                      <span className="icon">
                      <Delete/>
                      </span>
                    </button>
                  </>}
            
            </div>
          </div>) : <p className="nothing_found">Not found Partners</p>}
      </div>
      {offers ? <button className="btn-white add-partner" onClick={addNewPartner}>
            <span className="icon">
              <CirclePlus/>
            </span>
        <span>Add Offer</span>
      </button> : <button className="btn-white add-partner" onClick={addNewPartner}>
            <span className="icon">
              <CirclePlus/>
            </span>
        <span>Add Partner</span>
      </button>}
    </Wrapper>
  );
};

export default AdminPartnersItem;
