import React, { useState, useRef, useEffect} from "react";
import {Chart, Line} from "react-chartjs-2";
import { Wrapper } from "./styled";
import { IActiveChart } from "./types";
import {ActiveElement, ChartEvent} from "chart.js";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import moment from "moment";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const DashboardChart = ({
  promotionType,
  customers,
  salesStatistic,
  discount,
  dateFilter,
  endDate,
  statisticByLocation,
}: any) => {
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const chartRef = useRef<HTMLDivElement>(null);
  const [activeChart, setActiveChart] = useState<IActiveChart>("Gross Sales");
  const [hoveredChartItem, setHoveredChartItem] = useState(0)
  const [hoveredChartItemDiscount, setHoveredChartItemDiscount] = useState(0)
  const[customersForTable, setCustomersForTable] = useState<any[]>([])
  const[customersTransactionsForTable, setCustomersTransactionsForTable] = useState<any[]>([])
  
  useEffect(() => {
    const arr: any[] = []
    customers.forEach((item: any) =>{
      arr.push(item.value)
    })
    setCustomersForTable(arr)
  }, [customers]);
  
  const chartDetailsTableColumns = [
    {
      name: "Members",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.first_name} {row.last_name}
          </div>
        );
      },
    },{
      name: "I.D",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.barcode_content}
          </div>
        );
      },
    },
    {
      name: "Sale",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.sale}
          </div>
        );
      },
    },
    {
      name: "Discount",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.discount ? row.discount : 0}
          </div>
        );
      },
    },
    {
      name: "Order No",
      cell: (row: any): JSX.Element => {
        const url = `?${encodeURIComponent('code') + '=' + encodeURIComponent(row.barcode_content)}`
        return (
          <Link to={`/staff-discount-members/${url}`} className={'orderLink'} >
            {row.id
            }
          </Link>
        );
      },
    }
  ];
  
  const handleChangeActiveChart = (chartName: IActiveChart) => {
    setActiveChart(chartName);
    // todo
    setTimeout(() => {
      chartRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 200);
  };
  
  const hideTooltip = (elem: any) =>{
    if(elem.target.className === 'chart'){
      const customTooltip = document.getElementById('custom-tooltip');
      const arrow = document.getElementById('arrow');
      if (customTooltip && arrow) {
        customTooltip.style.display = 'none';
        arrow.style.display = 'none';
      }
    }
  }
  
  useEffect(() => {
    if(hoveredChartItem) {
      let discount = 0
      setCustomersTransactionsForTable(customers[hoveredChartItem].transactions)
      customers[hoveredChartItem].transactions.forEach((item: any) =>{
        discount+= +item.discount
      })
      setHoveredChartItemDiscount(discount)
    } else {
      setCustomersTransactionsForTable([])
      setHoveredChartItemDiscount(0)
    }
    
  }, [hoveredChartItem]);

  // @ts-ignore
  return (
    <Wrapper>
      <div className="nav-subpages">
        <div
          className={`nav-subpage ${
            activeChart === "Gross Sales" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Gross Sales")}
          >
            Gross Sales
          </button>
        </div>
        <div
          className={`nav-subpage ${
            activeChart === "Discount Issued" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Discount Issued")}
          >
            Discount Issued
          </button>
        </div>
        <div
          className={`nav-subpage ${
            activeChart === "Net Sales" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Net Sales")}
          >
            Net Sales
          </button>
        </div>
      </div>
      <div ref={chartRef} className="chart-wrapper">
        <div className="chart" onMouseLeave={(event) => {
          hideTooltip(event)
        }}>
          <div id="arrow" style={{position: 'absolute', display: 'none'}}></div>
          <div id="custom-tooltip" style={{position: 'absolute', display: 'none'}}>
            
            <div className="tooltip-header">
              <p className={'date'}>{customers.length > 0 && moment(customers[hoveredChartItem]?.date).format('DD MMM, YYYY')}</p>
              <p className={'info'}>Gross Sales: {customers.length > 0 && customers[hoveredChartItem]?.value},
                Discount: {loggedUser.user.currency_symbol}{parseFloat(`${hoveredChartItemDiscount}`).toFixed(2)}</p>
            </div>
            <div className="details">
              <DataTable fixedHeader fixedHeaderScrollHeight={'200px'} noHeader theme={'dark'} columns={chartDetailsTableColumns}
                         data={customersTransactionsForTable}/>
            </div>
          </div>
          <Line
            // type="line"
            height={80}
            data={{
              labels: customers
                .map((date: any, i: number) => {
                  const day = new Date(endDate);
                  if (dateFilter === 365) {
                    const newDate = new Date(
                      day.getFullYear(),
                      day.getMonth() - i,
                      1
                    );
                    return `${newDate.toLocaleString("en", {
                      month: "short",
                    })}`;
                  }
                  day.setDate(day.getDate() - i);
                  return `${day.getDate()} ${day.toLocaleString("en", {
                    month: "short",
                  })}`;
                })
                .reverse(),
              datasets: [
                {
                  data:
                    activeChart === "Gross Sales" ? customersForTable : activeChart === "Discount Issued" ? discount : salesStatistic,
                  borderColor:
                    activeChart === "Gross Sales" ? "#00A8FF" : activeChart === "Discount Issued" ? "#FF0000" : "#2ECC71",
                  pointRadius: 6,
                  borderWidth: 2,
                  pointBorderWidth: 2,
                  pointBorderColor: "#fff",
                  // hoverRadius: 8,
                  pointBackgroundColor:
                    activeChart === "Gross Sales" ? "#00A8FF" : activeChart === "Discount Issued" ? "#FF0000" : "#2ECC71",
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: activeChart !== 'Gross Sales',
                  displayColors: false,
                  intersect: false,
                  callbacks: {
                    title(tooltipItem: any[]) {
                      const date = new Date();
                      
                      if (dateFilter === 365) {
                        const newDate = new Date(
                          date.getFullYear(),
                          date.getMonth() + tooltipItem[0].dataIndex + 1,
                          1
                        );
                        return `${newDate.toLocaleString("en", {
                          month: "short",
                        })} ${newDate.getFullYear() - 1}`;
                      }
                      date.setDate(
                        date.getDate() -
                        (tooltipItem[0].dataset.data.length -
                          tooltipItem[0].dataIndex -
                          1)
                      );
                      
                      return `${tooltipItem[0].label}, ${date.getFullYear()}`;
                    },
                    label(tooltipItem: any) {
                      return `${
                        activeChart === "Gross Sales"
                          ? "Gross Sales"
                          : activeChart === "Discount Issued"
                            ? "Discount Issued"
                            : "Net Sales"
                      }: ${tooltipItem.formattedValue}`;
                    },
                  },
                },
              },
              onHover(event: ChartEvent, elements: ActiveElement[], chart: Chart) {
                if (elements.length && activeChart === 'Gross Sales') {
                  const firstElement = elements[0];
                  const index = firstElement.index;
                  
                  setHoveredChartItem(index)
                  const customTooltip = document.getElementById('custom-tooltip');
                  const arrow = document.getElementById('arrow');
                  // @ts-ignore
                  const chartWidth = document.querySelector('.chart').offsetWidth;
                  // @ts-ignore
                  const chartHeight = document.querySelector('.chart').offsetHeight;
                  const step = (550 / customers.length)
                  if (customTooltip && arrow) {
                    customTooltip.style.display = 'block';
                    arrow.style.display = 'block';
                    customTooltip.style.left = elements[0].element.x - step <= 0 ? 0 + 'px' : elements[0].element.x + 550 >= chartWidth ? chartWidth - 580 + 'px' : elements[0].element.x + 'px';
                    customTooltip.style.top = elements[0].element.y + 35 + customTooltip.offsetHeight >= chartHeight ? elements[0].element.y + 20 - customTooltip.offsetHeight + 'px' : elements[0].element.y + 40 + 'px';
                    arrow.style.left = elements[0].element.x + 25 + 'px';
                    arrow.style.top = elements[0].element.y + 35 + customTooltip.offsetHeight >= chartHeight ? elements[0].element.y + 15 + 'px' : elements[0].element.y +35 + 'px';
                  }
                }
              },
              interaction: {
                intersect: false,
                mode: 'index',
              },
              animation: {
                duration: 0,
              },
              scales: {
                x: {
                  beginAtZero: true,
                  ticks: {
                    precision: 0,
                  },
                },
                y: {
                  beginAtZero: true,
                  ticks: {
                    precision: 0,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default DashboardChart;
