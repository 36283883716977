import { Color } from "src/types/color";
import styled, {keyframes} from "styled-components";

import arrowDropDownImgBlack from "src/assets/img/arrowDropDownBlack.svg";
const uploadKeyFrames = keyframes`
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(8px);
  }
`;

export const Wrapper = styled.div`
  .top-bar div.search-wrap {
    margin-left: 0;
      input {
          padding: 11px 16px 11px 46px;
          margin-bottom: 10px;
      }
      svg{
          top: 43%;
      }
      
  }
    .modal-content{
        text-align: center;
        padding: 60px 50px;
        @media (max-width: 600px) {
            padding: 30px 15px;
        }
        .title {
            color: ${Color.green};
            font-size: 30px;
            font-weight: 500;
            line-height: 38px;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 23px;
                line-height: 28px;
            }
        }
        .sub-title{
            color: ${Color.blueDarkText};
            font-size: 25px;
            font-weight: 500;
            line-height: 38px;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 23px;
                line-height: 28px;
            }
        }
    }
    .data-wrap{
        .select{
            display: flex;
            flex-direction: column;
            position: relative;
            margin-bottom: 12px;
            
            & > .label:not(:empty) {
                color: ${Color.blueDarkText};
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 10px;
            }
            & > svg path:not([fill="none"]) {
                fill: #666e90;
            }
            & > svg {
                position: absolute;
                left: 8px;
                top: 8px;
            }

            & > input {
                border-radius: 2px;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
                padding: 11px 16px 11px 40px;
                font-size: 16px;
                line-height: 20px;
                cursor: pointer;
                background: url(${arrowDropDownImgBlack}) #ffffff no-repeat calc(100% - 12px) center;


                &::placeholder {
                    color: #95989d;
                }

                &:disabled:not(".lock") {
                    background: rgba(0, 0, 0, 0.07);
                    box-shadow: none;
                }
            }
            &.lock {
                input {
                    background: none;
                    cursor: initial;
                }
            }

            & > ul {
                position: absolute;
                width: 100%;
                background: #fff;
                z-index: 5;
                box-shadow: rgb(0 0 0 / 20%) 0px 2px 7px 0px;
                border-radius: 2px;
                padding: 5px 0;
                max-height: 230px;
                overflow: auto;
                &.top {
                    bottom: calc(100% + 7px);
                }
                &.bottom {
                    top: calc(100% + 7px);
                }

                &::-webkit-scrollbar {
                    width: 4px;
                    height: 4px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 144, 202, 0.4);
                }

                & > input {
                    width: 90%;
                    margin: 10px auto;
                    display: block;
                    padding: 7px 10px;
                    background: unset;
                    height: auto;
                    border: 2px solid #ebebeb;
                    border-radius: 6px;
                    box-sizing: border-box;

                    &::placeholder {
                        color: #ced2d4;
                    }
                }

                & > button {
                    font-size: 14px;
                    padding: 10px 16px;
                    cursor: pointer;
                    background: #fff;
                    transition: background-color 0.225s;
                    display: flex;
                    width: 100%;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.04);
                    }
                }

                & > li {
                    padding: 10px 16px;
                }
            }
            &:hover {
                .clearable-btn {
                    opacity: 0.4;
                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .lock-icon-wrap {
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
        }
    }
    .create-new-user {
        .label {
            display: flex;
            color: ${Color.blueDarkText};
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 10px;
            text-align: left;
        }
        .discountRule_wrapper, form {
            margin-bottom: 30px;
            border: 1px solid rgba(0, 13, 70, 0.1);
            border-radius: 5px;
            padding: 15px;
            overflow: hidden;
        }

        .title-close {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .title {
                font-size: 18px;
                font-weight: 500;
                line-height: 21px;
                margin-bottom: 0px;
            }

            .close {
                background: none;
                opacity: 0.6;
                line-height: 0;

                &:hover {
                    opacity: 1;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .saveCard-btn{
            width: 30%;
        }
        .addNew_rule{
            width: 30%;
            margin-top: 10px;
        }
        .input-wrap {
            display: flex;
            margin-bottom: 15px;
            width: 50%;
            flex-wrap: wrap;

            & > div {
                width: 45%;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                &.last_name-group{
                    &>div{
                        ul.bottom{
                            max-height: 70px;
                        }
                    }
                }
            }
        }
    }
  .form-file{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px
  }
  .staff-top-bar{
    justify-content: space-between;
  }
    .label {
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 10px;
        text-align: left
    }
    .colorInput{
        border-radius: 4px;
        //box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
        font-size: 18px;
        line-height: 20px;
        width: 100%;
        height: 48px;
        margin-top: 10px;
        background-color: transparent;
        margin-bottom: 10px;

        &::placeholder {
            color: #95989d;
        }

        &:disabled {
            padding: 13px 40px 13px 16px;
            /* background: rgba(0, 0, 0, 0.07);
            box-shadow: none; */
        }
    }
  .staff-add-btn{
    margin-left: 25px;
  }
    .selectImg {
                height: 200px;
                border-radius: 8px;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
                
                .dropZone {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    &.with-photo {
                        & > div {
                            .dropZone-content-text {
                                opacity: 0;
                                transition: 0.5s all ease;
                            }
                        }

                        &:hover {
                            & > div {
                                .dropZone-content-text {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    & > div {
                        position: relative;
                        height: 100%;
                        outline: none;
                        cursor: pointer;

                        &.active_true {
                            border-color: ${Color.blueDark};
                            box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 35%);
                        }

                        &.active_false {
                            min-height: 200px;
                        }

                        input {
                            max-width: 100%;
                            display: block !important;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            margin: auto;
                            opacity: 0;
                            z-index: -1;
                        }

                        .img {
                            line-height: 0;
                            width: 100%;
                            height: 100%;
                            img {
                                height: 100%;
                            }
                        }

                        .dropZone-content-text {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background-color: rgba(255, 255, 255, 0.85);

                            border: 1.5px dashed ${Color.blueDark};
                            border-radius: 4px;
                            outline: none;
                            padding: 30px;
                            cursor: pointer;
                            transition: all 0.225s cubic-bezier(0.77, 0.2, 0.05, 1);

                            & > svg {
                                margin-bottom: 5px;
                                animation: ${uploadKeyFrames} 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

                                path[fill-rule="evenodd"] {
                                    fill: ${Color.blueDark};
                                }
                            }

                            .text {
                                color: ${Color.blueDark};
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                span {
                                    margin-bottom: 5px;
                                }

                                .title {
                                    font-size: 18px;
                                    font-weight: 300;
                                    line-height: 23px;
                                }

                                .description {
                                    opacity: 0.6;
                                    font-size: 12px;
                                    font-weight: 300;
                                    line-height: 15px;
                                }

                                .file-name {
                                    font-size: 21px;
                                    font-weight: 300;
                                    line-height: 23px;
                                }
                            }
                        }
                    }
                }

                .img {
                    img {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
  .hide{
    display: none;
  }
    .discount_rules-list{
        .rdt_Table{
            .rdt_TableHead{
                .rdt_TableHeadRow{
                    .rdt_TableCol{
                        font-size: inherit;
                    }
                }
            }
            .rdt_TableBody{
                .rdt_TableRow{
                    align-items: center;
                    .rdt_TableCell{
                        color: ${Color.blueDarkText};
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        align-items: center;
                        height: 100%;
                        &>div{
                            width: 80%;
                            .isSelect{
                                ul.bottom{
                                    max-height: 80px;
                                }
                            }
                        }
                        .action {
                            display: flex;
                            align-items: center;
                            margin-bottom: 24px;
                            margin-top: 10px;
                            width: 30%;
                            .edit {
                                display: block;
                                line-height: 0;
                                height: 24px;
                                width: 24px;
                                opacity: 0.6;
                                margin-right: 25px;
                                background: none;
                                &:hover {
                                    opacity: 1;
                                }
                            }
                            .delete {
                                display: block;
                                line-height: 0;
                                height: 24px;
                                width: 24px;
                                opacity: 0.6;
                                background: none;
                                padding: 0;
                                &:hover {
                                    opacity: 1;
                                }
                            }
                            .info {
                                display: block;
                                line-height: 0;
                                height: 24px;
                                width: 24px;
                                margin-right: 25px;
                                background: none;
                                cursor: pointer;
                                position: relative;
                                & > span {
                                    z-index: -1;
                                    opacity: 0;
                                    background-color: $blueDarkText;
                                    position: absolute;
                                    font-size: 14px;
                                    width: 96px;
                                    color: $white;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    text-align: center;
                                    border-radius: 4px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    transition: all 0.3s ease;
                                    line-height: 20px;
                                    top: -55px;
                                    left: -35px;
                                    box-sizing: border-box;
                                }
                                svg {
                                    opacity: 0.6;
                                    &:hover {
                                        opacity: 1;

                                        & + span {
                                            z-index: 2;
                                            opacity: 1;
                                        }
                                    }
                                    path:not([fill="none"]) {
                                        fill: rgb(0, 13, 70);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .discount_ruls{
        &>div{
            ul{
                max-height: 80px;
            }
        }
    }
    .selectLogo-box{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .cardImage{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .cardView{
        border-radius: 15px;
        width: 400px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 50%;
            height: 50%;
            object-fit: contain;
        }
        
    }
  .table {
    & > div {
      // overflow: unset;
    }

    

    div.rdt_TableRow {

      & input {
        width:75%
      }
    }
    div:first-child {
      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${Color.white};
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${Color.blueText};
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: ${Color.blueLight};
        }

        &::-webkit-scrollbar-thumb:hover {
          background: ${Color.blueText};
        }
      }
    }
    
  }
`;
