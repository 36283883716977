import axios from "./api";

const getBusinessPlansAxios = (): Promise<any> =>
  axios
    .get("/public/getPlansInfo")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

const getBusinessOptionsAxios = (): Promise<any> =>
  axios
    .get("/api/business/options")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
const getOneBusinessOptionsAxios = ({id}: {id: number}): Promise<any> =>
  axios
    .get(`/api/business/options/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

const updateBusinessOptionsAxios = ({id, data}: {id: number, data: any}): Promise<any> =>
  axios
    .post(`/api/business/options/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export { getBusinessPlansAxios, getBusinessOptionsAxios, updateBusinessOptionsAxios, getOneBusinessOptionsAxios };
