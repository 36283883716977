import axios from "./api";

export const getPosUserIdListAxios = (
  requestParamtrs: {locationId: string | number}
): Promise<any> =>
  axios
    .post("/api/dashboard/staff/unique/list", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getStaffListAxios = (
  requestParamtrs: {limit: string| number,
    page: string | number,
    locationId: string | number,
    posUserId: string,
    startDate: Date | string,
    endDate: Date | string}
): Promise<any> =>
  axios
    .post("/api/dashboard/staff/info/list", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

